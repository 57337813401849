<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">
                <v-card>

                    <v-form @submit.prevent="login">
                        <v-card-title>{{ $t('login') }}</v-card-title>

                        <v-card-text>
                            <v-text-field
                                    id="emailField"
                                    :label="$t('email')"
                                    type="email"
                                    v-model="email"
                                    prepend-icon="mdi-account"
                            />

                            <v-text-field
                                    id="passwordField"
                                    :label="$t('password')"
                                    type="password"
                                    v-model="password"
                                    prepend-icon="mdi-lock"
                            />

                            <p class="mt-4">
                                <a id="passwordRequestLink"
                                   class="passwordRequestLink"
                                   @click="passwordRequest"
                                >
                                    {{ $t('forgot') }}
                                </a>
                            </p>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn id="registerButton" to="/register" color="success">
                                <v-icon dark left>mdi-account-plus</v-icon>
                                {{ $t('register') }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn id="loginButton" type="submit" color="info">
                                <v-icon dark left>mdi-account</v-icon>
                                {{ $t('login') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import userService from '@/services/UserService.js'

    export default {
        name: 'Login',

        data() {
            return {
                email: '',
                password: '',

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            login() {
                userService.login(
                    {
                        email: this.email,
                        password: this.password,
                    }
                )
                    .then(response => {
                        // Update store
                        this.$store.dispatch('login', response.data)

                        // Redirect to home page
                        this.$router.push('/home')
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('unknown_account')
                    })
            },

            passwordRequest() {
                // Redirect to password request page
                this.$router.push('/password_request')
            }
        },
    };
</script>

<style scoped>
    .passwordRequestLink {
        text-decoration: none;
    }
</style>
